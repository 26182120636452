import { useContext } from 'react'
import { AppContext } from '../App'
import { EMPTY_CONTACT, INPUTS } from '../static'

export const useFormVisibility = () => {
    const { setFormVisible, setEditedContact, setInputs } = useContext(AppContext)

    return () => {
        setFormVisible(prev => {
            if (prev === true) {
                setEditedContact(EMPTY_CONTACT)
                setInputs(INPUTS)
            }
            return !prev
        })
    }
}
