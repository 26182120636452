import React, { useEffect, useContext } from 'react'
import axios from 'axios'
import { AppContext } from '../App'
import { useFormVisibility } from '../hooks'
import Form from './Form'
import Search from './Search'
import ContactsList from './ContactsList'
import '../style.scss'

const PhoneBook = () => {
    const { contacts, setContacts, editedContact, isFormVisible } = useContext(AppContext)
    const toggleVisibility = useFormVisibility()

    useEffect(() => {
        axios(`${process.env.REACT_APP_API_URL}phonebook/`)
            .then(res => res.data)
            .then(data => {
                setContacts(data)
            })
    }, [])

    return (
        <div className='wrapper'>
            <h1>Phone Book App</h1>
            <div className='row'>
                <h3>Contacts</h3>
                {!editedContact.id && (
                    <button
                        onClick={toggleVisibility}
                        disabled={isFormVisible}
                    >
                        + Add Contact
                    </button>
                )}
            </div>
            {isFormVisible && (
                <Form />
            )}
            <Search />
            {!!contacts.length
                ? <ContactsList />
                : <div className='empty'>There are no contacts yet</div>
            }
        </div>
    )
}

export default PhoneBook
