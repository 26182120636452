import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { AppContext } from '../App'
import SearchIcon from '../assets/images/search.svg'

const Search = () => {
    const { setContacts } = useContext(AppContext)
    const [search, setSearch] = useState('')

    const handleSearch = (e) => {
        setSearch(e.target.value)
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}phonebook/search?search=${search}`)
            .then(res => res.data)
            .then(data => {
                setContacts(data)
            })
    }, [search])

    return (
        <div className='search'>
            <img src={SearchIcon} alt="" />
            <input
                type="text"
                placeholder="Search for contact by last name..."
                value={search}
                onInput={handleSearch}
            />
        </div>
    )
}

export default Search
