import React, { createContext, useState } from 'react'
import PhoneBook from './components/PhoneBook'
import { EMPTY_CONTACT, INPUTS } from './static'

export const AppContext = createContext(null)

const App = () => {
    const [contacts, setContacts] = useState([])
    const [search, setSearch] = useState('')
    const [isFormVisible, setFormVisible] = useState(false)
    const [editedContact, setEditedContact] = useState(EMPTY_CONTACT)
    const [inputs, setInputs] = useState(INPUTS)

    const contextValue = {
        contacts,
        setContacts,
        search,
        setSearch,
        isFormVisible,
        setFormVisible,
        editedContact,
        setEditedContact,
        inputs,
        setInputs
    }

    return (
        <AppContext.Provider value={contextValue}>
            <PhoneBook />
        </AppContext.Provider>
    )
}

export default App
