export const INPUTS = [
    {
        name: 'firstname',
        label: 'Enter First Name',
        value: '',
        maxLength: '100'
    },
    {
        name: 'lastname',
        label: 'Enter Last Name',
        value: '',
        maxLength: '100'
    },
    {
        name: 'phone_number',
        label: 'Enter Phone',
        value: '',
        maxLength: '11'
    }
]

export const EMPTY_CONTACT = {
    firstname: '',
    lastname: '',
    phone_number: '',
    id: ''
}
