import React, { useContext } from 'react'
import axios from 'axios'
import { AppContext } from '../App'
import { useFormVisibility } from '../hooks'
import EditIcon from '../assets/images/edit.png'
import DeleteIcon from '../assets/images/delete.png'
import '../style.scss'

const ContactsList = () => {
    const { contacts, setContacts, setEditedContact, setInputs, isFormVisible } = useContext(AppContext)
    const toggleVisibility = useFormVisibility()

    const handleEditContact = (contact) => {
        setEditedContact({
            firstname: contact.firstname,
            lastname: contact.lastname,
            phone_number: contact.phone_number,
            id: contact.id
        })

        setInputs(prev => {
            return [...prev].map(el => {
                return {
                    ...el, value: contact[el.name]
                }
            })
        })

        if (!isFormVisible) {
            toggleVisibility()
        }
    }

    const handleDeleteContact = (id) => {
        axios.delete(`${process.env.REACT_APP_API_URL}phonebook/${id}`)
            .then(() => {
                setContacts(prev => [...prev].filter(contact => contact.id !== id))
                if (isFormVisible) {
                    toggleVisibility()
                }
            })
    }

    return (
        <div className='list'>
            {contacts.map(contact =>
                <div className='item' key={contact.id}>
                    <div className='col'>
                        <div className='name'>{contact.firstname} {contact.lastname}</div>
                        <div>{contact.phone_number}</div>
                    </div>
                    <div className='buttons'>
                        <button onClick={() => handleEditContact(contact)}>
                            <img src={EditIcon} alt="" />
                        </button>
                        <button onClick={() => handleDeleteContact(contact.id)}>
                            <img src={DeleteIcon} alt="" />
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ContactsList
