import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios';
import { AppContext } from '../App'
import { useFormVisibility } from '../hooks'
import { EMPTY_CONTACT } from '../static'
import '../style.scss'

const Form = () => {
    const { inputs, setInputs, editedContact, setEditedContact, contacts, setContacts } = useContext(AppContext)
    const toggleVisibility = useFormVisibility()

    const [isSubmitDisabled, setSubmitDisabled] = useState(false)
    const validate = () => {
        return inputs.some(input => !input.value.length)
    }

    useEffect(() => {
        const isValid = validate()
        setSubmitDisabled(isValid)
    }, [inputs])

    const handleInputChange = (val, index) => {
        let updated = [...inputs]
        updated[index] = {
            ...inputs[index],
            value: val
        }
        setInputs(updated)
    }

    const handleChangeContact = (e) => {
        e.preventDefault()

        const isEdit = editedContact.id

        let contact = {
            firstname: e.target.firstname.value,
            lastname: e.target.lastname.value,
            phone_number: e.target.phone_number.value
        }

        if (isEdit) contact.id = editedContact.id

        axios.put(`${process.env.REACT_APP_API_URL}phonebook`, contact)
            .then(res => res.data)
            .then(data => {
                if (isEdit) {
                    const index = contacts.findIndex(item => item.id === editedContact.id)
                    let updated = [...contacts]
                    updated[index] = contact
                    setContacts(updated)
                } else {
                    contact.id = data.id
                    setContacts(prev => [contact, ...prev])
                }
            })

        setEditedContact(EMPTY_CONTACT)

        toggleVisibility()
    }

    return (
        <form className='form' onSubmit={handleChangeContact}>
            <div className='form-inputs'>
                {inputs.map(({ name, label, value, maxLength }, index) =>
                    <div className='input' key={name}>
                        <label htmlFor={name}>{label}</label>
                        <input
                            type="text"
                            name={name}
                            id={name}
                            value={value}
                            onChange={e => handleInputChange(e.target.value, index)}
                            maxLength={maxLength}
                        />
                    </div>
                )}
            </div>
            <div className='buttons'>
                <button
                    className='cancel'
                    onClick={toggleVisibility}
                >
                    Cancel
                </button>
                <button
                    type='submit'
                    disabled={isSubmitDisabled}
                >
                    Submit
                </button>
            </div>
        </form>
    );
};

export default Form;
